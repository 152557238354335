import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  AppLayout,
  BreadcrumbGroup,
  ColumnLayout,
  Container,
  ExpandableSection,
  FormField,
  Header,
  Spinner,
  Table,
} from '@awsui/components-react';


export default function TeacherQuizPage(props) {
  // variables
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [navOpen, setNavOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [quiz, setQuiz] = useState(null);

  // get quiz from API
  useEffect(() => {
    async function getQuiz() {
      // get quiz
      var quiz = await props.api.get({ path: `/quizzes/${id}`});
      setQuiz(quiz);

      // convert questions to array
      var questions = [];
      quiz.sections.forEach((section) => {
        // loop through questions
        section.questions.forEach((question) => {
          questions.push({
            ...question,
            sectionType: section.content.type,
            sectionId: section.content.id,
          });
        });
      });
      setQuestions(questions);

      // finish loading
      setLoading(false);
    };

    getQuiz();
  }, []);

  // return component
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={
            [
              {
                text: 'Teachers',
                href: '#/teachers',
              },
              {
                text: 'Quizzes',
                href: '#/teachers/quizzes',
              },
              {
                text: quiz ? quiz.name : id,
                href: `#/teachers/quizzes/${id}`,
              },
            ]
          }
        />
      }
      content={
        <>
          {
            loading ?
              <Spinner />
              :
              <ColumnLayout columns={1}>
                <Container
                  header={
                    <Header>{quiz.name}</Header>
                  }
                >
                  <ColumnLayout columns={3}>
                    <FormField
                      label='ID'
                      description='Unique identifier of this quiz.'
                      stretch={true}
                    >
                      {quiz.id}
                    </FormField>
                    <FormField
                      label='Class'
                      description='Class of students that will take this quiz.'
                      stretch={true}
                    >
                      <Link to={`/teachers/classes/${quiz.classroom}`}>{quiz.classroom}</Link>
                    </FormField>
                    <FormField
                      label='Creates at'
                      description='When this quiz was made.'
                      stretch={true}
                    >
                      {new Date(quiz.createdAt).toDateString()}
                    </FormField>
                    <FormField
                      label='Student link'
                      description='URL for students to use to take the quiz.'
                      stretch={true}
                    >
                      <a target='_blank' href={`https://quizloop.ninja/#/quizzes/${quiz.id}`}>{`https://quizloop.ninja/#/quizzes/${quiz.id}`}</a>
                    </FormField>
                  </ColumnLayout>
                  <ColumnLayout columns={1}>
                    <FormField
                      label='Raw JSON'
                      description='This is the object as it is stored in the database.'
                      stretch={true}
                    >
                      <ExpandableSection>
                        <pre>{JSON.stringify(quiz, null, 2)}</pre>
                      </ExpandableSection>
                    </FormField>
                  </ColumnLayout>
                </Container>
                <Table
                  header={<Header>Students</Header>}
                  items={questions}
                  columnDefinitions={
                    [
                      {
                        id: 'sectionId',
                        header: 'Section',
                        cell: (item) => item.sectionId,
                      },
                      {
                        id: 'text',
                        header: 'Text',
                        cell: (item) => item.text,
                      },
                      {
                        id: 'answers',
                        header: 'Answers',
                        cell: (item) => item.answers.join(' | '),
                      },
                      {
                        id: 'correctAnswer',
                        header: 'Correct answer',
                        cell: (item) => item.answers[0],
                      },
                      {
                        id: 'sectionType',
                        header: 'Section Type',
                        cell: (item) => item.sectionType,
                      },
                    ]
                  }
                />
              </ColumnLayout>
          }
        </>
      }
      navigation={props.nav}
      navigationOpen={navOpen}
      onNavigationChange={({ detail: { open }}) => {setNavOpen(open);}}
      toolsHide={true}
      headerSelector='#site-header'
      footerSelector='#site-footer'
    />
  )
}