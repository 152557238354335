import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  AppLayout,
  BreadcrumbGroup,
  ColumnLayout,
  Container,
  ExpandableSection,
  FormField,
  Header,
  Spinner,
  Table,
} from '@awsui/components-react';


export default function TeacherClassPage(props) {
  // variables
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [classroom, setClassroom] = useState(null);
  const [navOpen, setNavOpen] = useState(false);
  const [students, setStudents] = useState({});

  // get class from API
  useEffect(() => {
    async function getQuiz() {
      setClassroom(await props.api.get({ path: `/classes/${id}`}));
      setLoading(false);
    };

    getQuiz();
  }, []);

  // get students
  useEffect(() => {
    // get all students
    // TODO: Move to utility
    async function getStudents() {
      // loop through all students
      classroom.students.forEach(async (currentStudent) => {
        // get particular student
        var result = await props.api.get({ api: 'StudentAPI', path: `/students/${currentStudent.email}`});

        // set the student map
        setStudents((prevStudents) => ({...prevStudents, [currentStudent.email]: result}));
      });
    };

    // start API calls
    if (classroom) {
      getStudents();
    }
  }, [classroom]);

  // return component
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={
            [
              {
                text: 'Teachers',
                href: '#/teachers',
              },
              {
                text: 'Classes',
                href: '#/teachers/classes',
              },
              {
                text: classroom ? `${classroom.semester}, ${classroom.id}` : id,
                href: `#/teachers/classes/${id}`,
              },
            ]
          }
        />
      }
      content={
        <>
          {
            loading ?
              <Spinner />
              :
              <ColumnLayout columns={1}>
                <Container
                  header={
                    <Header>
                      {
                        classroom ?
                          `${classroom.semester}, ${classroom.id}`
                          :
                          id
                      }
                    </Header>
                  }
                >
                  <ColumnLayout columns={3}>
                    <FormField
                      label='ID'
                      description='Unique identifier of this class in the semester.'
                      stretch={true}
                    >
                      {classroom.id}
                    </FormField>
                    <FormField
                      label='Semester'
                      description='Section of the year that will the class takes place.'
                      stretch={true}
                    >
                      {classroom.semester}
                    </FormField>
                    <FormField
                      label='Creates at'
                      description='When this class was made.'
                      stretch={true}
                    >
                      {new Date(classroom.createdAt).toDateString()}
                    </FormField>
                  </ColumnLayout>
                  <ColumnLayout columns={1}>
                    <FormField
                      label='Raw JSON'
                      description='This is the object as it is stored in the database.'
                      stretch={true}
                    >
                      <ExpandableSection>
                        <pre>{JSON.stringify(classroom, null, 2)}</pre>
                      </ExpandableSection>
                    </FormField>
                  </ColumnLayout>
                </Container>
                <Table
                  header={<Header>Students</Header>}
                  items={(classroom && classroom.students) || []}
                  columnDefinitions={
                    [
                      {
                        id: 'email',
                        header: 'Email',
                        cell: (item) => item.email,
                      },
                      {
                        id: 'firstName',
                        header: 'First name',
                        cell: (item) => students[item.email] && students[item.email].firstName,
                      },
                      {
                        id: 'lastName',
                        header: 'Last name',
                        cell: (item) => students[item.email] && students[item.email].lastName,
                      },
                      {
                        id: 'specialNeedsLevel',
                        header: 'Special needs Level',
                        cell: (item) => (students[item.email] && students[item.email].specialNeedsLevel) || 0,
                      },
                    ]
                  }
                />
              </ColumnLayout>
          }
        </>
      }
      navigation={props.nav}
      navigationOpen={navOpen}
      onNavigationChange={({ detail: { open }}) => {setNavOpen(open);}}
      toolsHide={true}
      headerSelector='#site-header'
      footerSelector='#site-footer'
    />
  )
}