import React from 'react';
import { Link } from 'react-router-dom';
import {
  AppLayout,
  BreadcrumbGroup,
  Cards,
} from '@awsui/components-react';

export default function TeacherPage(props) {
  // return component
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={
            [
              {
                text: 'Teachers',
                href: '#/teachers',
              },
            ]
          }
        />
      }
      content={
        <Cards
          cardsPerRow={
            [
              {
              cards: 1
              }, {
                minWidth: 500,
                cards: 2
              }, {
                minWidth: 800,
                cards: 3
              },
            ]
          }
          cardDefinition={
            {
              header: (item) => <Link to={item.path} >{item.header}</Link>,
              sections:
                [
                  {
                    header: 'Description',
                    content: (item) => item.description,
                  }
                ]
            }
          }
          items={
            [
              {
                header: 'Classes',
                description: 'Create, review, and edit classes.',
                path: '/teachers/classes',
              },
              {
                header: 'Quizzes',
                description: 'Create, review, and edit quizzes.',
                path: '/teachers/quizzes',
              },
            ]
          }
        />
      }
      navigation={props.nav}
      toolsHide={true}
      headerSelector='#site-header'
      footerSelector='#site-footer'
    />
  )
}