import React from 'react';
import { useLocation } from 'react-router-dom';
import { SideNavigation } from '@awsui/components-react';


export default function SiteNavigation() {
  // variables
  const location = useLocation();

  // return component
  return (
    <SideNavigation
      activeHref={`#${location.pathname}`}
      header={{ text: 'Quizloop', href: '#/' }}
      items={[
        {
          type: 'expandable-link-group',
          text: 'Teachers',
          href: '#/teachers',
          items: [
            {
              type: 'link',
              text: 'Classes',
              href: '#/teachers/classes',
            },
            {
              type: 'link',
              text: 'Quizzes',
              href: '#/teachers/quizzes',
            },
          ],
        },
      ]}
    />
  );
}