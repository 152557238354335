import Amplify, { API } from 'aws-amplify';


export default class ApiClient {

  constructor() {
    Amplify.configure({
      API: {
        endpoints: [
          {
            name: 'TeacherAPI',
            endpoint: 'https://p7nc5szh54.execute-api.us-east-1.amazonaws.com/Teacher',
          },
          {
            name: 'StudentAPI',
            endpoint: 'https://by57syhmvf.execute-api.us-east-1.amazonaws.com/Student',
          },
        ]
      },
    });
  }

  async prepRequest(params) {
    // variables
    const request = {
      api: params.api || 'StudentAPI',
      path: params.path,
    };

    // check auth
    if (request.api === 'TeacherAPI') {
      request.headers = { 
        Authorization: 'troywynn',
      };
    }

    // add optional variables
    if (params.query) {
      request.queryStringParameters = params.query;
    }
    if (params.body) {
      request.body = params.body;
    }

    // return request object
    return request;
  }

  async get(params) {
    // make request
    try {
      // prepare request
      const request = await this.prepRequest(params);
      
      // get results
      const result = await API.get(request.api, request.path, request);

      // return results
      return result;
    } catch(err) {
      // log error for debug
      console.log(err);

      // create response
      if (Math.random() < 0.5) {
        //this.signIn();
      }

      // return response
      return err;
    }
  }

  signIn() {
    window.location.href = `https://quizloop.auth.us-east-1.amazoncognito.com/login?response_type=token&client_id=7upfv288244ee1364f76h5nf86&redirect_uri=${window.location.href}`;
  }

}