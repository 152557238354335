import React from 'react';


export default class Sprite extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id                 : props.id || new Date().getTime(),
      width              : props.width || 11,
      height             : props.height || 34,
      scale              : props.scale || 1,
      heading            : props.heading || 0,
      x                  : props.x || 0,
      y                  : props.y || 0,
      position           : props.position || 'absolute',
      drawX              : props.drawX || 0,
      drawY              : props.drawY || 0,
      borderless         : props.borderless || false,
      edgeBrightness     : props.edgeBrightness || 0.3,
      colorVariations    : props.colorVariations || 0.2,
      brightnessNoise    : props.brightnessNoise || 0.3,
      saturation         : props.saturation || 0,
      hue                : props.hue || -1,
      lighting           : props.lighting || 1,
      saturationOffset   : props.saturationOffset || -1,
      saturationCeiling  : props.saturationCeiling || 1,
      saturationFloor    : props.saturationFloor || 0,
      diffuseByDefault   : props.diffuseByDefault || false,
      diffusionCycles    : props.diffusionCycles || 0, // How many cycles the diffusion process will go through
      diffusionChance    : props.diffusionChance || 1, // the chance of neighboring cells diffusing
      diffusionGradient  : props.diffusionGradient || 0.9, // the brightness drop off of diffused spots
      diffuseAttempts    : props.diffuseAttempts || -1, // number of times a cell can be diffused to, -1 = infinite
      difussionAdditive  : props.difussionAdditive || false,
      mirrorX            : props.mirrorX || false,
      mirrorY            : props.mirrorY || false,
      centerMergedX      : props.centerMergedX || false,
      centerMergedY      : props.centerMergedY || false,
      mask               : props.mask || [
        [{},{},{},{},{},{},{border:1},{},{},{},{}],
        [{},{},{},{},{},{},{border:1},{},{},{},{}],
        [{},{},{},{},{},{},{},{border:1},{},{border:1},{border:1}],
        [{},{},{},{},{},{},{},{},{border:1},{body:1},{body:1}],
        [{},{},{},{},{},{},{},{border:1},{border:1},{border:1},{body:1}],
        [{},{},{},{},{},{},{},{border:1},{border:1},{border:1},{body:1}],
        [{},{},{},{},{},{},{},{border:1},{border:1},{border:1},{body:1}],
        [{},{},{},{},{},{},{},{},{border:1},{body:1},{body:1}],
        [{},{},{},{},{},{},{},{},{},{border:1},{border:1}],
        [{},{},{},{},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1}],
        [{},{body:1},{body:1},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{body:1},{body:1},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{body:1},{body:1},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{},{},{},{},{},{border:1},{border:1,body:1}],
        [{},{},{},{},{},{body:1},{body:1},{},{border:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{body:1},{body:1},{},{},{body:1},{border:1},{border:1,body:1},{border:1,body:1}],
        [{},{body:1},{body:1},{},{},{},{},{},{border:1},{border:1},{border:1,body:1}],
        [{},{},{},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{body:1},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{body:1},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{body:1},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{body:1},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{body:1},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{},{},{},{},{border:1},{border:1,body:1},{border:1,body:1}],
        [{},{},{},{},{},{},{},{},{},{border:1},{border:1}],
      ],
      refs: {},
    };


    this.sanatizeMask = this.sanatizeMask.bind(this);
    this.renderMask = this.renderMask.bind(this);
    this.rgbWithNoise = this.rgbWithNoise.bind(this);
    this.rgbSolid = this.rgbSolid.bind(this);
    this.getRgb = this.getRgb.bind(this);
    this.draw = this.draw.bind(this);
    this.move = this.move.bind(this);
  }

  componentDidMount() {
    this.setState({ mask: this.sanatizeMask() }, () => {
      this.renderMask();
      this.draw();
    });
  }

  render() {
    return (
      <canvas ref={(ref) => this.state.refs[this.state.id] = ref } style={{transform: `rotate(${this.state.heading}deg)`, position: this.state.position, top: (this.state.y - this.state.height * this.state.scale * (this.state.mirrorY ? 2 : 1) * 0.5), left: this.state.x - this.state.width * this.state.scale * (this.state.mirrorX ? 2 : 1) * 0.5}} width={this.state.width * this.state.scale * (this.state.mirrorX ? 2 : 1)} height={this.state.height * this.state.scale * (this.state.mirrorY ? 2 : 1)} />
    );
  }

  sanatizeMask() {
    // clone mask
    var mask =  JSON.parse(JSON.stringify(this.state.mask));

    // calculate dimensions
    this.state.height = mask.length;
    this.state.width = mask.length && Math.max.apply(Math,mask.map(function(o){return o.length;}));

    // square up mask
    for (var i = 0; i < this.state.height; i++) {
      if (this.state.width > mask[i].length) {
        for (var j = mask[i].length; j < this.state.width; j++) {
          mask[i].push({}); // push empty object, which defaults to empty
        }
      }
    }

    // mirror x
    this.state.drawWidth = this.state.width;
    if (this.state.mirrorX) {
      var halfX = this.state.width - this.state.centerMergedX;
      for (var y = 0; y < this.state.height; y++) {
        for (var rx = halfX - 1; rx >= 0; rx--) {
          if(y == 1) {
            // console.log('mirror', mask[y][rx], rx, y);
          }
          mask[y].push(mask[y][rx]);
        }
        if(y == 1) {
          // console.log('mirror', mask[y]);
        }
      }
      this.state.drawWidth = mask[0].length;
    }

    // mirror y
    this.state.drawHeight = this.state.height;
    if (this.state.mirrorY) {
      var halfY = this.state.height - this.state.centerMergedY;
      for (var y = halfY - 1; y >= 0; y--) {
        mask.push(mask[y]);
      }
      this.state.drawHeight = mask.length;
    }

    // center sprite on mask
    this.state.x += this.state.drawWidth / 2;
    this.state.y += this.state.drawHeight / 2;

    // return
    return mask;
  }

  draw() {
    var scaledWidth = this.state.drawWidth * this.state.scale;
    var scaledHeight = this.state.drawHeight * this.state.scale;

    // create pixel data array
    // one RGBA value for each pixel in linear array
    var ctx = this.state.refs[this.state.id].getContext('2d');
    var pixels = ctx.getImageData(this.state.drawX, this.state.drawY, scaledWidth, scaledHeight);

    // color each pixel
    var xKeys = Object.keys(this.state.mask[0]);
    Object.keys(this.state.mask).forEach(y => {
      for (var ys = 0; ys < this.state.scale; ys++) {
        xKeys.forEach(x => {
          for (var xs = 0; xs < this.state.scale; xs++) {
            // calculate linear offset
            var xOffset = (x * this.state.scale) + xs;
            var index = ((y * (scaledWidth * this.state.scale)) + (ys * scaledWidth) + xOffset) * 4;

            // get pixel color
            var rgb = this.getRgb(x, y);
            
            if(y == 1 && x == 17) {
              // console.log('rgb', rgb, x, y);
            }

            // write pixel color data
            pixels.data[index + 0] = rgb.r;
            pixels.data[index + 1] = rgb.g;
            pixels.data[index + 2] = rgb.b;
            pixels.data[index + 3] = rgb.a;
          }
        });
      }
    });
    
    // write pixel image
    ctx.putImageData(pixels, this.state.drawX, this.state.drawY);
  }

  renderMask() {
    // get general color values
    var saturation = this.state.saturation || (((this.state.saturationCeiling - this.state.saturationFloor) * Math.random()) + this.state.saturationFloor);
    var hue = this.state.hue < 0 ? Math.random() : this.state.hue;

    // color each pixel
    for (var y = 0; y < this.state.height; y++) {
      for (var x = 0; x < this.state.width; x++) {
        var maskPixel = this.state.mask[y][x];

        // check pixel type(s) set
        var typeSet = false;
        pixelTypes.forEach(key => {
          typeSet |= maskPixel[key];
        })
        if (typeSet) {
          // calculate pixel type
          var sum = 0;
          pixelTypes.forEach(key => {
            sum += maskPixel[key] || 0;
          });
          var type;
          var cap = 0;
          var rand = Math.random() * sum;
          for (var s = 0; s < pixelTypes.length; s++) {
            cap += maskPixel[pixelTypes[s]] || 0;
            if (rand < cap) {
                type = pixelTypes[s];
                break;
              }
          }
          maskPixel.type = type;

          // apply coloring based on type
          switch(type) {
            case 'body':
              // calculate color
              maskPixel.rgb = this.rgbWithNoise(maskPixel.hue || hue, maskPixel.saturation || saturation, true);

              // stop diffusion
              delete maskPixel.nextDiffusion;

              // remove type setters
              delete maskPixel.border;
              delete maskPixel.empty;
              break;
            case 'border':
              // calculate color
              maskPixel.rgb = this.rgbWithNoise(maskPixel.hue || hue, maskPixel.saturation || saturation, true, this.state.edgeBrightness);

              // update diffusion
              if (maskPixel.body) {
                maskPixel.nextDiffusion = this.state.diffuseByDefault ? maskPixel.diffuse : null;
              }
              if (maskPixel.diffuse) {
                maskPixel.nextDiffusion = maskPixel.diffuse;
              }

              // remove type setters
              delete maskPixel.body;
              delete maskPixel.empty;
              break;
            case 'color':
              // set color
              maskPixel.rgb = this.rgbSolid(maskPixel);

              // remove type setters
              delete maskPixel.border;
              delete maskPixel.body;
              delete maskPixel.empty;
              break;
            case 'empty': default:
              // don't fill anything in
              // remove type setters
              delete maskPixel.border;
              delete maskPixel.body;
              break;
          }
        }
      }
    }

    // diffuse spots
    var hasDiffused = true;
    var diffusionBrightness = this.state.edgeBrightness;
    for (var d = 0; d < this.state.diffusionCycles && hasDiffused; d++) {
      hasDiffused = false;
      diffusionBrightness = 1 - this.state.diffusionGradient + (this.state.diffusionGradient * diffusionBrightness);

      // unstage next diffusion
      for (var y = 0; y < this.state.height; y++) {
        for (var x = 0; x < this.state.width; x++) {
          this.state.mask[y][x].diffusion = this.state.mask[y][x].nextDiffusion;
        }
      }

      // calculate diffusion
      for (var y = 0; y < this.state.height; y++) {
        for (var x = 0; x < this.state.width; x++) {
          var maskPixel = this.state.mask[y][x];
          var nextDiffusion = maskPixel.diffusion * this.state.diffusionChance;

          // check cell can diffuse
          if (maskPixel.diffusion) {
            // diffuse in 4 directions
            for (var ny = Math.max(y - 1, 0); ny <= y + 1 && ny < this.state.height; ny++) {
              for (var nx = Math.max(x - 1, 0); nx <= x + 1 && nx < this.state.width; nx++) {
                if ((nx !== x) ^ (ny !== y)) {
                  // only apply to body regions
                  if (this.state.mask[ny][nx].type === 'body') {
                    // update diffusion counter
                    if (this.state.mask[ny][nx].diffusionCounter) {
                      this.state.mask[ny][nx].diffusionCounter++;
                    } else {
                      this.state.mask[ny][nx].diffusionCounter = 1;
                    }

                    // check if diffuse
                    if ((this.state.diffuseAttempts < 0 || this.state.mask[ny][nx].diffusionCounter <= this.state.diffuseAttempts) && Math.random() < maskPixel.diffusion) {
                      // make into border
                      this.state.mask[ny][nx].type = 'border';
                      this.state.mask[ny][nx].rgb = this.rgbWithNoise(hue, saturation, true, diffusionBrightness);

                      // give best diffusion chance
                      this.state.mask[ny][nx].nextDiffusion = this.state.difussionAdditive ? (this.state.mask[ny][nx].nextDiffusion || 0) + nextDiffusion :  Math.max(this.state.mask[ny][nx].nextDiffusion || 0, nextDiffusion);

                      // Flag as having diffused this.state cycle
                      hasDiffused = true;
                    }
                  }
                }
              }
            }
          }

          // clear the current diffusion
          delete maskPixel.diffusion;
        }
      }
    }

    // outline body with edged
    for (var y = 0; y < this.state.height; y++) {
      for (var x = 0; x < this.state.width; x++) {
        var maskPixel = this.state.mask[y][x];

        if (maskPixel.rgb === undefined) {
          var rgb = this.rgbWithNoise(hue, saturation, false);

            if (!this.state.borderless) {
            // check for neighboring body pixel
            // TODO: look for more efficient edge checker
            for (var ny = Math.max(y - 1, 0); ny <= y + 1 && ny < this.state.height && !maskPixel.rgb; ny++) {
              for (var nx = Math.max(x - 1, 0); nx <= x + 1 && nx < this.state.width; nx++) {
                if ((nx !== x) ^ (ny !== y)) {
                  if (this.state.mask[ny][nx].body) {
                    // color like border
                    rgb = this.rgbWithNoise(hue, saturation, true, this.state.edgeBrightness);
                    break;
                  }
                }
              }
            }
          }

          // write pixel color data
          maskPixel.rgb = rgb;
        }
      }
    }
  }

  rgbWithNoise(h, s, a, brightness = 1) {
    var copies = (this.state.mirrorX ? 2 : 1) * (this.state.mirrorY ? 2 : 1);
    var rgb = [];

    for (var i = 0; i < copies; i++) {
      // switch for tranparent
      if (a) {
        // generate color with brightness
        var l = this.state.lighting * (1 - this.state.brightnessNoise) + Math.random() * this.state.brightnessNoise;
        var rgba = this.hslToRgb(h, s, l);

        // apply brightness
        rgba.r *= brightness;
        rgba.g *= brightness;
        rgba.b *= brightness;

        // save color
        rgb.push(rgba);
      } else {
        // save color
        rgb.push({ r: 0, g: 0, b: 0, a: 0 });
      }
    }

    return rgb;
  }

  getRgb(x, y) {
    var index = ((x >= this.state.width ? 2 : 1) * (y >= this.state.height ? 2 : 1)) - 1;

    if(y == 1 && x == 17) {
      // console.log('mask', this.state.mask[y][x], x, y);
    }
    return this.state.mask[y][x].rgb[index];
  }

  hslToRgb(h, s, l, a = 1) {
    var rgb = { r: 1, g: 1, b: 1, a: a}; // default to white
    var i, f, p, q, t;

    // calculate the modulo 
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = l * (1 - s);
    q = l * (1 - f * s);
    t = l * (1 - (1 - f) * s);
    
    switch (i % 6) {
        case 0: rgb.r = l; rgb.g = t; rgb.b = p; break;
        case 1: rgb.r = q; rgb.g = l; rgb.b = p; break;
        case 2: rgb.r = p; rgb.g = l; rgb.b = t; break;
        case 3: rgb.r = p; rgb.g = q; rgb.b = l; break;
        case 4: rgb.r = t; rgb.g = p; rgb.b = l; break;
        case 5: rgb.r = l; rgb.g = p; rgb.b = q; break;
    }

    // scale to 255
    rgb.r *= 255;
    rgb.g *= 255;
    rgb.b *= 255;
    rgb.a *= 255;

    return rgb;
  }

  rgbSolid(maskPixel) {
    var copies = (this.state.mirrorX ? 2 : 1) * (this.state.mirrorY ? 2 : 1);
    var rgb = [];

    for (var i = 0; i < copies; i++) {
      rgb.push({
        r: maskPixel.r * 255 || 0,
        g: maskPixel.g * 255 || 0,
        b: maskPixel.b * 255 || 0,
        a: maskPixel.a * 255 || 255
      });
    }

    return rgb;
  }

  move(dx, dy, dh = 0, xmin = this.state.x + dx, xmax = this.state.x + dx, ymin = this.state.y + dy, ymax = this.state.y + dy) {
    // calculate heading
    dh += this.state.heading;
    while (dh < 0 || dh >= 360) {
      dh = (dh + 360) % 360;
    }

    // calculate next x position
    var speculativeX = this.state.x + dx;
    dx += this.state.x;
    if (xmin) {
      dx = Math.max(dx, xmin);
    }
    if (xmax) {
      dx = Math.min(dx, xmax);
    }

    // calculate next y position
    var speculativeY = this.state.y + dy;
    dy += this.state.y;
    if (ymin) {
      dy = Math.max(dy, ymin);
    }
    if (ymax) {
      dy = Math.min(dy, ymax);
    }

    // bounce off edges
    if ((speculativeY != dy) || (speculativeX != dx)) {
      dh += 180;
    }

    // set new position
    this.setState({ x: dx, y: dy, heading: dh })
  }

}


const pixelTypes = [
  'border',
  'body',
  'color',
  'empty',
];