import React from 'react';
import Sprite from './Sprite';
import StringUtils from '../utilities/StringUtils';
import { useState } from 'react';
import { useEffect } from 'react';


export default function SiteHeader() {
    // varaibles
    const [antRef, setAntRef] = useState(0)
    const [ant] = useState(<Sprite ref={ref => {setAntRef(ref)}} id={StringUtils.uuid()} mirrorX={true} scale={2} />);
    
    // move on to screen
    useEffect(() => {
      if (antRef) {
        antRef.move(30,8,90);
      }
    }, [antRef]);

    return (
      <div id='site-header' style={{ height: '3.1em', background: '#ddce7d' }}>
        {ant}
        <span className='title' style={{ margin: '0 0 0 2.8em', padding: '0', fontSize: '1.8em', fontWeight: 'bold' }}>QuizLoop</span>
      </div>
    );
}