import React, { useState } from 'react';
import StringUtils from '../utilities/StringUtils';


export default function Material(props) {
  // variables
  const [uuid] = useState(StringUtils.uuid());

  // return component
  return (
    <video id={uuid} className='fullwidth' controls onEnded={props.onEnd} autoPlay >
      <source src={`https://quizloop-content.s3.amazonaws.com/videos/${props.quiz.sections[props.step - 1].content.id}`} type="video/mp4" />
    </video>
  );

}