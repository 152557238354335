import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppLayout,
  Button,
  Container,
  Form,
  Header,
  Select,
  Spinner,
} from '@awsui/components-react';
import Material from '../../../components/Material';


export default function QuizPage(props) {
  // variables
  const { id } = useParams();
  const [classroom, setClassroom] = useState(null);
  const [questionsAnswered, setQuestionsAnswered] = useState(false);
  const [quiz, setQuiz] = useState(null);
  const [showMaterial, setShowMaterial] = useState(true);
  const [step, setStep] = useState(0);
  const [student, setStudent] = useState(null);
  const [studentOption, setStudentOption] = useState(null);

  // get quiz from API
  useEffect(() => {
    async function getQuiz() {
      // get quiz
      var quiz = await props.api.get({ path: `/quizzes/${id}`});
      setQuiz(quiz);
      console.log(quiz);
    };

    getQuiz();
  }, []);

  // get class from API
  useEffect(() => {
    async function getClassroom(id) {
      setClassroom(await props.api.get({ path: `/classes/${id}`}));
    };

    if (quiz && quiz.classroom) {
      getClassroom(quiz.classroom);
    }
  }, [quiz]);

  // get the active student from the API
  async function getStudent(email) {
      // get particular student
      var result = await props.api.get({ api: 'StudentAPI', path: `/students/${email}`});

      // set the student map
      setStudent(result);

      // TODO: Add metric call for starting quiz.
  };

  // return component
  return (
    <AppLayout
      content={
        <Form
          actions={
            step ?
              // next question / section
              <Button variant='primary' onClick={() => setStep(step + 1)} >Next</Button>
              :
              <Button variant='primary' disabled={!student} onClick={() => setStep(step + 1)} >Select user</Button>
          }
        >
          {
            step ?
              // quiz
              <Container>
                {
                  showMaterial ?
                    <Material quiz={quiz} step={step} onEnd={() => setShowMaterial(false)} />
                    :
                    <pre>{JSON.stringify(quiz.sections[step - 1].questions, null, 2)}</pre>
                }
              </Container>
              :
              // student selector
              classroom ?
                <Container
                  header={
                    <Header>
                      Select user
                    </Header>
                  }
                >
                  <Select
                    selectedOption={studentOption}
                    options={
                      classroom.students.map((item) => {return { id: item.email, value: item.name }})
                    }
                    onChange={({ detail: { selectedOption }}) => {setStudentOption(selectedOption); getStudent(selectedOption.id)}}
                  />
                </Container>
                :
                <Spinner />
          }
        </Form>
      }
      navigation={props.nav}
      toolsHide={true}
      navigationHide={true}
      headerSelector='#site-header'
      footerSelector='#site-footer'
    />
  )
}