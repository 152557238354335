import React, { useEffect } from 'react';
import {
  AppLayout
} from '@awsui/components-react';

export default function Homepage(props) {
  // variables
  async function test() {
    console.log('starting');
    console.log((await props.api.get({ path: '/quizzes/test'})));
  }

  // test api
  useEffect(() => {
    test();
  }, [])

  // return component
  return (
    <AppLayout
      content={
        <div>Homepage</div>
      }
      navigation={props.nav}
      toolsHide={true}
      headerSelector='#site-header'
      footerSelector='#site-footer'
    />
  )
}