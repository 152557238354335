import React, { useState } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import './styles/App.css';
import API from './utilities/API';
import SiteHeader from './components/SiteHeader';
import SiteNavigation from './components/SiteNavigation';
import Homepage from './pages/Homepage';
import TeacherPage from './pages/teachers/TeachersPage';
import QuizzesPage from './pages/teachers/quizzes/QuizzesPage';
import TeacherQuizPage from './pages/teachers/quizzes/id/TeacherQuizPage';
import ClassesPage from './pages/teachers/classes/ClassesPage';
import TeacherClassPage from './pages/teachers/classes/id/TeacherClassPage';
import QuizPage from './pages/quizzes/id/QuizPage';


export default function App() {
  // variables
  const [api] = useState(new API());
  const nav = <SiteNavigation/>;
  const domain = window.location && window.location.host;
  const location = useLocation();

  // return component
  return (
    <div className='awsui'>
      <SiteHeader/>
      {
        // check if it is the teacher site (or localohost)
        /^(teacher\.quizloop\.ninja|lolcalhost\:3000)$/.test(domain) ?
          <Switch>
            <Route exact path='/teachers/classes/:id' render={(props) => <TeacherClassPage {...props} api={api} nav={nav} />} />
            <Route exact path='/teachers/classes' render={(props) => <ClassesPage {...props} api={api} nav={nav} />} />
            <Route exact path='/teachers/quizzes/:id' render={(props) => <TeacherQuizPage {...props} api={api} nav={nav} />} />
            <Route exact path='/teachers/quizzes' render={(props) => <QuizzesPage {...props} api={api} nav={nav} />} />
            <Route exact path='/teachers' render={(props) => <TeacherPage {...props} api={api} nav={nav} />} />
            <Route render={(props) => <TeacherPage {...props} api={api} nav={nav} />} />
          </Switch>
          :
          <Switch>
            <Route exact path='/teachers/classes/:id' render={(props) => <TeacherClassPage {...props} api={api} nav={nav} />} />
            <Route exact path='/teachers/quizzes/:id' render={(props) => <TeacherQuizPage {...props} api={api} nav={nav} />} />
            <Route exact path='/quizzes/:id' render={(props) => <QuizPage {...props} api={api} nav={nav} />} />
            <Route render={(props) => <Homepage {...props} api={api} nav={nav} />} />
          </Switch>
      }
      {/* TODO: Add 404 page */}
    </div>
  );
}