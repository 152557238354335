import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppLayout,
  BreadcrumbGroup,
  Header,
  Table,
} from '@awsui/components-react';

export default function ClassesPage(props) {
  // variables
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState([]);

  // get quizzes from API
  useEffect(() => {
    async function getQuizzes() {
      setClasses(await props.api.get({ api: 'TeacherAPI', path: '/classes'}));
      setLoading(false);
    };

    getQuizzes();
  }, []);

  // return component
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={
            [
              {
                text: 'Teachers',
                href: '#/teachers',
              },
              {
                text: 'Classes',
                href: '#/teachers/classes',
              },
            ]
          }
        />
      }
      content={
        <Table
          header={
            // TODO: Add create button
            <Header
              counter={classes.length ? `(${classes.length})` : ''}
            >
              Classes
            </Header>
          }
          loading={loading}
          columnDefinitions={
            [
              {
                id: 'id',
                header: 'ID',
                cell: (item) => <Link to={`/teachers/classes/${item.semester}|${item.id}`} >{item.id}</Link>,
              },
              {
                id: 'semester',
                header: 'Semester',
                cell: (item) => item.semester,
              },
              {
                id: 'teacher',
                header: 'Teacher',
                cell: (item) => item.teacher,
              },
              {
                id: 'createdAt',
                header: 'Created at',
                cell: (item) => new Date(item.createdAt).toDateString(),
              },
            ]
          }
          items={classes}
        />
      }
      navigation={props.nav}
      toolsHide={true}
      headerSelector='#site-header'
      footerSelector='#site-footer'
    />
  )
}